<template>
  <el-card id="creatSeat">
    <header>
      <h1>坐席管理</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap" v-if="roleName == '管理员'">
            <span>企业ID</span>
            <el-input
              v-model="id"
              placeholder="请输入企业ID"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>主叫</span>
            <el-input
              v-model="activePhone"
              placeholder="请输入主叫"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap" v-if="realname == 'admin'">
            <span>状态</span>
            <el-select v-model="value" placeholder="请选择状态" size="small">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap" v-if="realname == 'admin'">
            <span>排序方式</span>
            <el-select
              v-model="orderBySeatMoney"
              placeholder="请选择排序方式"
              style="width: 500px"
            >
              <el-option
                v-for="item in sortord"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            class="searchBtn"
            size="small"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            class="reset"
            size="small"
            >重置</el-button
          >

          <el-button
            type="success"
            icon="el-icon-mic"
            @click="addSeat()"
            class="addSeats"
            v-if="realname == 'admin' || roleName == '企业'"
            size="small"
            >创建坐席</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-folder-opened"
            @click="allSeats()"
            class="allSeats"
            v-if="realname == 'admin'"
            size="small"
            >批量创建</el-button
          >

          <el-button
            type="primary"
            @click="allBuy()"
            class="allSeats"
            size="small"
            v-if="roleName == '企业'"
            >批量购买</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userId"
          label="企业ID"
          align="center"
          v-if="roleName == '管理员'"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="坐席ID"
          align="center"
          v-if="roleName == '管理员'"
        ></el-table-column>
        <el-table-column
          prop="activePhone"
          label="手机号码（账号）"
          align="center"
          v-if="encryption != -1"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号码（账号）"
          align="center"
          v-if="encryption == -1"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="真实姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seatMoney"
          label="套餐金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="buyCount"
          label="购买次数"
          align="center"
        ></el-table-column>
        <el-table-column prop="seatType" label="套餐外消费" align="center">
          <template #default="scope">
            <span v-if="scope.row.seatType == 0">否</span>
            <span v-else-if="!scope.row.seatType"></span>
            <span v-else>是</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createdTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 0" style="color: red"
              >等待验证</span
            >
            <span v-if="scope.row.status == 1">已通过验证</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="province"
          label="省份"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city"
          label="城市"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="400"
          align="center"
          style="dispaly: flex"
        >
          <template #default="scope">
            <div class="button-list">
              <el-button
                type="primary"
                @click="revalidation(scope)"
                v-if="scope.row.status == 0"
                size="small"
                >重新验证</el-button
              >
              <el-button type="primary" @click="changeNums(scope)" size="small"
                >变更号码</el-button
              >
              <el-button
                type="success"
                @click="passInspect(scope)"
                v-if="realname == 'admin' && scope.row.status == 0"
                size="small"
                >通过验证</el-button
              >
              <el-button
                type="danger"
                @click="del(scope)"
                v-if="
                  realname == 'admin' ||
                  parentld == 3555 ||
                  roleName == '企业'
                "
                size="small"
                >删除坐席</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 创建坐席弹出框 -->
    <el-dialog title="创建坐席" :visible.sync="seatVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="账号" v-if="realname == 'admin'">
          <el-select v-model="form.userId" filterable placeholder="请选择公司">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主叫号码">
          <el-input
            v-model="form.activePhone"
            placeholder="请输入主叫号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="名字">
          <el-input v-model="form.name" placeholder="请输入名字"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="seatVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveSeat">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量创建弹出框 -->
    <el-dialog
      title="批量绑定"
      :visible.sync="allVisible"
      width="30%"
      class="importAll"
    >
      <div class="content">
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          >点击下载模板</el-button
        >
        <el-upload
          :file-list="fileList"
          :on-exceed="handleExceed"
          :on-change="changeUpload"
          :limit="1"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          class="upload-demo"
          drag="true"
          action=""
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload()" :disabled="disabled"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 变更号码弹框 -->
    <el-dialog title="变更号码" :visible.sync="changeVisible" width="30%">
      <el-form ref="changeform" :model="form" label-width="100px">
        <el-form-item label="主叫号码">
          <el-input
            v-model="changeform.activePhone"
            placeholder="请输入主叫号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="名字">
          <el-input
            v-model="changeform.name"
            placeholder="请输入名字"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="changeVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量购买弹框 -->
    <el-dialog title="批量购买" :visible.sync="allBuyVisible" width="30%">
      <span>确定批量购买套餐包？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="allBuyVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="allBuySubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  seatPhoneList,
  seatPhoneAdd,
  seatPhoneImportSeat,
  seatPhoneGetCode,
  seatPhoneUpdate,
  seatPhoneDel,
  seatPhoneBuySeatMoney,
} from "@/api/seat-phone";
import { userFindlist } from "@/api/user";
export default {
  data() {
    return {
      numsArray: [], //选中号码的ID
      allBuyVisible: false,
      orderBySeatMoney: null,
      sortord: [
        {
          value: null,
          label: "创建时间排序",
        },
        {
          value: 1,
          label: "最低金额排序",
        },
      ],
      fileList: [],
      encryption: false,
      realname: null,
      roleName: null,
      parentld: null,
      id: null,
      activePhone: null,
      name: null,
      value: null,
      disabled: false,
      userId: null,
      changeId: null,
      seatVisible: false,
      allVisible: false,
      changeVisible: false,
      options: [],
      form: {
        userId: "",
        activePhone: "",
        name: "",
        realname: "",
      },
      options1: [
        {
          value: 0,
          label: "未通过验证",
        },
        {
          value: 1,
          label: "已通过验证",
        },
      ],
      changeform: {
        id: null,
        name: null,
        activePhone: null,
        status: null,
        departId: null,
      },
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.isMonthEnd();
    this.parentld = localStorage.getItem("parentld");
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.encryption = localStorage.getItem("encryption");
    this.getList();
  },
  methods: {
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(111111111, this.numsArray);
    },
    // 批量购买弹出框
    allBuy() {
      // console.log(this.tableData);
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.allBuyVisible = true;
    },
    // 确认批量购买
    allBuySubmit() {
      let userId = localStorage.getItem("id");
      let params = {
        listId: this.numsArray,
        userId,
      };
      let res = seatPhoneBuySeatMoney(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.allBuyVisible = false;
        this.getList();
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 月底隐藏删除按钮
    isMonthEnd() {
      let date = new Date();
      let currentMonth = date.getMonth();
      let nextMonth = ++currentMonth;
      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      console.log("当月最后一天", day);
      let currentDay = date.getDate(); // 获取当前日(1-31)
      console.log("当天", currentDay);
      // if (currentDay == day) {
      //   return (this.lastDay = 1);
      // } else {
      //   return (this.lastDay = 0);
      // }
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        status: this.value,
        activePhone: this.activePhone,
        orderBySeatMoney: this.orderBySeatMoney,
        userId: this.id,
        // stop: this.stop,
      };
      let res = seatPhoneList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    //重置
    reset() {
      this.orderBySeatMoney = null;
      (this.activePhone = null), (this.value = null), (this.id = null);
      this.getList();
    },
    getCompany() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res = userFindlist(params);
      res.then((res) => {
        this.options = res.data.data;
      });
      this.addVisible = false;
    },
    //点击创建坐席
    addSeat() {
      (this.form = []), (this.seatVisible = true), this.getCompany();
    },
    //添加坐席确定按钮
    saveSeat() {
      let params = {
        userId: this.form.userId,
        activePhone: this.form.activePhone,
        name: this.form.name,
      };
      let res = seatPhoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      });
      this.seatVisible = false;
    },
    //批量创建
    allSeats() {
      this.disabled = false;
      this.allVisible = true;
    },
    //下载模板
    upload() {
      window.open("http://113.250.189.239:9005/seat-phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(11111111111, file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    //批量创建
    submitUpload() {
      this.allVisible = false;
      this.disabled = true;
      let formDate = new FormData();
      formDate.append("file", this.file);
      let res = seatPhoneImportSeat(formDate);
      res.then((res) => {
        if ((res.data.statusCode = "00000")) {
          Message.success(res.data.message);
          this.file = {};
          this.getList();
        } else {
          Message.error(res.data.message);
          this.file = {};
          this.getList();
        }
      });
    },
    //重新验证按钮
    revalidation(row) {
      let params = {
        activePhone: row.row.activePhone,
      };
      let res = seatPhoneGetCode(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.getList();
        } else {
          Message.error(res.data.message);
        }
      });
    },
    //变更号码
    changeNums(row) {
      this.changeVisible = true;
      // console.log(row.row.activePhone);
      this.changeform.activePhone = row.row.activePhone;
      this.changeId = row.row.id;
      this.changeform.status = row.row.status;
      this.changeform.name = row.row.name;
      // console.log(row.row);
    },
    //变更号码确定按钮
    changeSubmit() {
      let params = {
        activePhone: this.changeform.activePhone,
        name: this.changeform.name,
        id: this.changeId,
        status: this.changeform.status,
      };
      let res = seatPhoneUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.changeVisible = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.changeVisible = false;
          this.getList();
        }
      });
    },
    //通过验证
    passInspect(row) {
      this.$confirm("是否确定通过验证，通过后将自动创建用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params = {
            status: 1,
            id: row.row.id,
            activePhone: row.row.activePhone,
            name: row.row.name,
            userId: row.row.userId,
          };
          let res = seatPhoneUpdate(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.getList();
            } else {
              Message.error(res.data.message);
              this.getList();
            }
          });
        })
        .catch(() => {
          Message.info("已取消通过操作");
        });
    },
    //删除坐席
    del(row) {
      // console.log(row);
      this.$confirm(
        "是否确定删除坐席?删除后套餐无法退回且将删除所有相关数据（不可恢复）！！！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          let params = {
            id: row.row.id,
            activePhone: row.row.activePhone,
            name: row.row.name,
            userId: row.row.userId,
          };
          let res = seatPhoneDel(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.getList();
            } else {
              Message.error(res.data.message);
              this.getList();
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
  },
};
</script>

<style lang="less" scoped>
#creatSeat {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 21%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .uploadBtn {
    width: 68%;
  }
}
</style>